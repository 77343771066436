@import '../../../../../variables.scss';


.Reservations {


    .p-datatable .p-datatable-thead>tr>th {
        text-align: left;
        font-size: 12px !important;

    }

    .p-datatable .p-datatable-tbody>tr {
        text-align: left;
        font-size: 12px !important;

    }



    .p-calendar input {
        border-radius: 0px !important;
        min-height: 2.5em;
    }

    .circle-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .circle {
            display: flex;
            justify-content: center;
            align-items: center;
            width: auto;
            flex: 1;
            max-width: 3em;
            margin-right: 1em;

            .inner-circle {
                margin: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 2em;
                height: 2em;
                border-radius: 50%;

                i {
                    display: flex;
                    font-size: 1em;
                    margin-left: .1em;


                    &.icon-cv19-circle-check {
                        margin-top: .1em;
                    }
                }
            }

            &.approved {
                background: $lightest-green;

                .inner-circle {
                    background: $green;
                }

                i {
                    color: $white !important;
                }
            }

            &.pending {
                background: $pending-yellow-light;

                .inner-circle {
                    background: $pending-yellow;
                }

                i {
                    color: $white !important;
                }
            }

            &.rejected,
            &.cancelled_no_show,
            &.cancelled {
                background: $delete-red-light;

                .inner-circle {
                    background: $delete-red;
                }

                i {
                    color: $white !important;
                }
            }

            &.info {
                background: $pending-yellow-light;

                .inner-circle {
                    background: $pending-yellow;
                }

                i {
                    color: $white !important;
                }
            }

            &.checkedin {

                .inner-circle {
                    background: $green;
                }

                i {
                    color: $white !important;
                }
            }

            &.checkout {

                .inner-circle {
                    background: $gray;
                }

                i {
                    color: $white !important;
                }
            }


        }
    }
}

.confirmButton {
    width: 100%;
    text-align: center;

    .ui.button {
        background-color: $green ;
        height: 35px;
        border-radius: 5rem !important;
        color: white;

        &:hover {
            background-color: #2F786E;
        }
    }

}

.backButton {
    width: 100%;
    text-align: center;

    .ui.button {
        background-color: rgba(0, 0, 0, 0);
        height: 35px;
        border: 1px solid grey;
        border-radius: 5rem !important;
        font-weight: 500;
    }
}


.formStyleDialog {
    background-color: rgba(0, 0, 0, 0) !important;
    padding: 30px;
    max-width: 100% !important;
}



.formGroupButton {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
    align-items: center !important;
    display: flex;
    flex-direction: row;
}

.p-calendar .p-datepicker {
    position: relative;

}

body {
    .iconReservation {
        margin: 0 !important;
    }
    
    .p-calendar.p-calendar-w-btn .p-inputtext {
        border-right: 1px solid rgba(34, 36, 38, .15) !important;
        border-radius: 5rem 5rem 5rem 5rem !important
    }
    .p-dropdown {
        .p-dropdown-label {
            font-size: 12px !important;

        }
    }
}

.p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button {
    height: 2.11428571em;
}

.p-dialog .p-dialog-content {
    overflow-y: visible !important
}

