@import '../../../variables.scss';

.Header {
    min-height: $header-height;

    h3 {
        color: $white;
        font-weight: 300;
        margin: 0;
    }

    h3.username {
        font-size: 1rem;
    }

    .icon-cv19-switcher {
        height: 0.8em;
    }

    i {
        color: $white;
        font-weight: bold;
        font-size: 1.2rem;
        height: auto;
        cursor: pointer;

        &:hover {
            color: $cgreen;
        }
    }

    .p-dropdown {
        background: transparent;
        border: none;
        display: flex;
        align-items: center;


        .p-dropdown-label {
            background: transparent;
            color: $white;
        }

        .pi-chevron-down:before {
            font-size: .8em;
            color: $white;
        }


    }

    .p-overlaypanel {
        border-radius: .7em;
    }

    .p-overlaypanel::before,
    .p-overlaypanel::after {
        display: none;
    }

    .circle {
        min-width: 3.5em;
        cursor: pointer;

        &.grey-bg {
            i {
                color: $detail-color;

            }

            &:hover {
                i {
                    color: $cgreen;
                }
            }
        }


    }


    .cgreen-bg {
        transition: background .1s linear;

        &:hover {
            background: $detail-bg;

            i {
                color: $cgreen;
            }
        }
    }



}

body .p-dropdown-panel {
    border: none;

    .p-dropdown-item.p-highlight {
        background-color: $green;
    }
}

body .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
body .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group.p-highlight {
    background-color: $green;
}

.logo-container {
    margin-left: 1%;
}

.button-container {
    margin-left: 10%;
    height: 100%;

    .ui.button {
        background: $green !important;
        color: $white;
        height: 100%;
        font-size: 1rem;
        color: $white;
        font-weight: 300;
        margin: 0;

        &.active,
        &:hover {
            background:rgba(41,138,126) !important;
            color: $black !important;
        }

    }


}


.logo {
    height: 30px;
}

.sub-logo {
    font-size: 10px;
    color: $white;
    font-weight: 300;
}

.button {
    height: 50px;
}

body .p-button {
    background-color: transparent;
    border: transparent;
    hover: transparent;
}

body .p-button:hover {
    background-color: transparent;
    border: transparent;
}

.item-menu {
    margin-right: 4px;
    margin-left: 4px;
}

.item-menu:hover {
    background-color: #0000000e;
    border-radius: 8px;
}

.headerLink {
    color: $white;
    &:hover {
        color: $white;
        text-decoration: none
    }
}