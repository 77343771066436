@import '../../../../../variables.scss';

@media only screen and (max-width: 760px) {
    .Home {
        
        .ui.tabular.menu {
            border:none;
            height: $tab-height;
            min-height: $tab-height;
            margin-bottom: 50px;
    
            .active.item {
                border: none;
                border-radius: 0px;
                border-bottom: 4px solid $cgreen;
            }
        }

        .ui.attached.tabular.menu{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }   
    
        .ui.attached.segment {
            border:none;
            border-top: 0px solid #d4d4d5;
            height: 100%;
        }
    
    }
  }

@media only screen and (min-width: 759px) {
    .Home {
        //margin-bottom:30px;

        .ui.tabular.menu {
            border:none;
            height: $tab-height;
            min-height: $tab-height;
            

            .active.item {
                border: none;
                border-radius: 0px;
                border-bottom: 4px solid $cgreen;
            }
        }

        .ui.attached.segment {
            border:none;
            border-top: 1px solid #d4d4d5;
            height: 100%;
        }


    }
}