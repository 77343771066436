@import '../../../../../variables.scss';

body {
    .container {
        display: flex;
        // align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 50px;
    }

    .ui.selection.dropdown {
        border-radius: 5rem !important;
        width: 100%;
        // height: 2.11428571em;
        height: 2.11428571em;

        min-height: 2.11428571em;
        padding: .48571429em 2.1em .78571429em 1em;
    }

    .ui.active.visible.selection.dropdown {
        border-radius: 1.5rem 1.5rem 1.5rem 1.5rem !important;
        width: 100%;
    }

    // .notVisibleCalendar>.p-input-overlay-visible {
    //     display: none !important;

    // }

    // .visibleCalendar>.p-input-overlay-visible {
    //     display: block !important;

    // }



    .ui.form .field>label {
        text-align: left;
        font-weight: 600;
    }

    .ui.form .selectionUsers>label {
        text-align: center;
        font-weight: 600;
    }

    .ui.form .field .ui.input input,
    .ui.form .fields .field .ui.input input {
        // border-radius: 5rem !important;
        height: 2.11428571em;
        min-height: 2.11428571em;
    }

    .ui.action.input>.button {
        border-radius: 10rem 0 0 10rem;
        min-height: 2.11428571em;
        height: 2.11428571em;
        font-size: 1em;
        background-color: $green;
        color: white;
        width: 2.557em !important;

        &:hover {
            background-color: #2F786E;
        }
    }

    .ui.action.input:not([class*="left action"])>.button:last-child {
        border-radius: 0 10rem 10rem 0;
        min-height: 2.11428571em;
        height: 2.11428571em;
    }

    .p-calendar {
        width: 98%;
    }

    .ui.button:not(.icon)>.icon:not(.button):not(.dropdown) {
        margin: 0 0.42857143em 0 -0.4128571em;
    }

    .p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button {
        background-color: $green;
    }

    .p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button {
        border-radius: 5rem 5rem 5rem 5rem;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        width: 2.557em !important;
        margin-left: -23px;
    }



    .ui.multiple.search.dropdown {
        border-radius: 0 10rem 10rem 0;
        min-height: 2.11428571em;
        // height: 2.11428571em;
        padding: 0.3em 0.5em 0.5em 1.2em;
    }

    // .ui.default.dropdown:not(.button)>.text, .ui.dropdown:not(.button)>.default.text {
    //     margin: 0
    // }

    .ui.dropdown .menu>.item>.description {
        background-color: $green;
        color: white;
        padding: 3px;
    }

    .numberDescriptionFalse>.description {
        background-color: red !important;
        color: white;
        padding: 3px;
    }

    .numberDescriptionTrue>.description {
        background-color: $green !important;
        color: white;
        padding: 3px;
    }

    .selectionUsers {
        padding: 0.3em 0.3em 0.3em 1em;
        height: auto !important;
    }

}

.p-calendar.p-calendar-w-btn .p-inputtext {
    border-right: 1px solid rgba(34, 36, 38, .15) !important;
    border-radius: 5rem 5rem 5rem 5rem;
    height: 2.11428571em !important;
}

.trasparenceButton {
    background-color: transparent;
}
.headerDropdown {
    display: block;
    margin: 0 0 0.28571429rem 0;
    color: rgba(0, 0, 0, .87);
    font-size: .92857143em;
    font-weight: 400;
    text-transform: none;
}

.formStyle {
    background-color: rgba(247, 246, 246);
    ;
    padding: 30px;
    max-width: 100% !important;
}

.confirmButton {
    .ui.button {
        background-color: $green ;
        height: 35px;
        border-radius: 5rem !important;
        color: white;
        &:hover {
            background-color: #2F786E;
        }
    }

}

.ui.selection.dropdown .menu {
    margin-top: 5px;
    border-top-width: 1px !important;
    border-radius: 0.4rem !important;

}

.backButton {
    .ui.button {
        background-color: rgba(0, 0, 0, 0);
        height: 35px;
        border: 1px solid grey;
        border-radius: 5rem !important;
        font-weight: 500;
    }
}

.selectionUsers {
    padding: 1em;
}

.fieldDropdown {
    width: 100%;
}



.formGroup {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
    text-align: left;
}

.formGroupButton {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
    align-items: left;
}

.title {
    text-align: left;
    font-weight: 200;
    font-size: 1.5em;
}

.ui.form {
    margin-top: 10px;
}

.p-calendar .p-inputtext {
    min-height: 2.11428571em !important;
}

.ui.multiple.dropdown>.label {
    font-size: 0.8em;
    margin: 0
}
.ui.search {
    font-size: 1em;
}

.p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button {
    height: 2.11428571em;
}

.calendarNewForm {
    .p-datepicker {
        position: absolute;
    }

    .p-datepicker table {
        font-size: 10.5px;
    }

}

.ui.selection.dropdown>.delete.icon, .ui.selection.dropdown>.dropdown.icon, .ui.selection.dropdown>.search.icon {
    top: 8px
}