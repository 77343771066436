@font-face {
  font-family: 'Covid19-Icons';
  src: url('./icons/covid19-tracker.eot?lt7uef');
  src: url('./icons/covid19-tracker.eot?lt7uef#iefix') format('embedded-opentype'), url('./icons/covid19-tracker.ttf?lt7uef') format('truetype'), url('./icons/covid19-tracker.woff?lt7uef') format('woff'), url('./icons/covid19-tracker.svg?lt7uef#covid19-tracker') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Covid19-Icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-cv19-user:before {
  content: "\e918";
}

.icon-cv19-add:before {
  content: "\e916";
}
.icon-cv19-close:before {
  content: "\e917";
}

.icon-cv19-virus:before {
  content: "\e915";
}

.icon-cv19-clock:before {
  content: "\e911";
}

.icon-cv19-pin:before {
  content: "\e912";
}

.icon-cv19-filter:before {
  content: "\e913";
}

.icon-cv19-search:before {
  content: "\e914";
}

.icon-cv19-print-outline:before {
  content: "\e90c";
}

.icon-cv19-levels:before {
  content: "\e90d";
}

.icon-cv19-trash-outline:before {
  content: "\e90e";
}

.icon-cv19-switcher:before {
  content: "\e90f";
}

.icon-cv19-seat:before {
  content: "\e910";
}

.icon-cv19-tag:before {
  content: "\e900";
}

.icon-cv19-circle-check:before {
  content: "\e901";
}

.icon-cv19-email:before {
  content: "\e902";
}

.icon-cv19-trash:before {
  content: "\e903";
}

.icon-cv19-edit:before {
  content: "\e904";
}

.icon-cv19-print:before {
  content: "\e905";
}

.icon-cv19-upload:before {
  content: "\e906";
}

.icon-cv19-warning:before {
  content: "\e907";
}

.icon-cv19-password:before {
  content: "\e908";
}

.icon-cv19-user:before {
  content: "\e909";
}

.icon-cv19-company:before {
  content: "\e90a";
}

.icon-cv19-logout:before {
  content: "\e90b";
}