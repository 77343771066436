//COLORS
$cyan: rgba(39, 187, 204, 1);
$green: rgba(71,177,163);
// $green: rgba(45, 187, 84, 1);
// $light-green: rgba(96, 194, 107, 1);
$light-green: rgba(71,177,163);
$lightest-green: rgba(209, 239, 212, 1);
$cgreen: rgba(48, 185, 151, 1);
$light-cgreen: rgb(164, 238, 220);
$white: rgba(255, 255, 255, 1);
$black: rgba(0, 0, 0, 1);
$gray: rgba(71, 71, 71, 0.596);
$detail-bg: rgba(239, 238, 239, 1);
$detail-color: rgba(127, 127, 127, 1);
$block-line-color: rgba(198, 198, 198, 1);
$delete-red: rgba(203, 45, 45, 1);
$delete-red-light: rgba(248, 214, 214, 1);
$pending-yellow: rgba(255, 202, 131, 1);
$pending-yellow-light: rgba(255, 244, 229, 1);


//COLORS
$dark-green: rgba(60, 160, 120, 1);
$grey: rgba(96, 96, 96, 1);


//CLASSES

.green-bg {
    background: $green;
}

.ui.button.green-bg {
    background: $green !important;
    color:$white;
}
.google-button {
    background: url("https://www.google.it/images/branding/googlelogo/2x/googlelogo_color_160x56dp.png") 3px 5px no-repeat;
}

.ui.button.google-button {
    background: url("https://www.google.it/images/branding/googlelogo/2x/googlelogo_color_160x56dp.png") no-repeat !important;
    color:red;
}
.cyan-bg {
    background: $cyan;
}

//SIZES

$header-height: 60px;
$toolbox-buttons-height: 40px;
$tbh-offset: 60px;
$tab-height: 45px;

//CLASSES



.black {
    color: $black;
}

.green {
    color: $green;
}

.green-bg {
    background: $light-green;
    transition: background .4s linear;

    &:hover {
        background: $light-green;
    }
}

.delete-red {
    color: $delete-red;
}

.detail-grey {
    color: $detail-color;
}

.cgreen-bg {
    background: $cgreen;
    transition: background .4s linear;
}

.lightest-green-bg {
    background: $lightest-green;
    transition: background .4s linear;
}

.light-green-bg {
    background: $light-green;
    transition: background .4s linear;
}

.light-cgreen-bg {
    background: $light-cgreen;
    transition: background .4s linear;
}

.white-bg {
    background: $white;
    transition: background .4s linear;
}

.grey-bg {
    background: $detail-bg;
    transition: background .4s linear;

}

.ui.button.green-bg {
    background: $light-green !important;
    color: $white;
}

.cyan-bg {
    background: $cyan;
}