@import '../../../../../variables.scss';

body {

}
.container {
    display: flex;
    // align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px;
}
.formStyle {
    background-color: rgba(247, 246, 246);;
    padding: 30px;
}

.confirmButton{
    .ui.button {
        background-color: $green ;
        height: 35px;
        border-radius: 5rem !important;
    }
}

.backButton{
    .ui.button {
        background-color: rgba(0, 0, 0, 0);
        height: 35px;
        border: 1px solid grey;
        border-radius: 5rem !important;
    }
}

.ui label > .labelDesc
     {
        background-color: transparent;
        font-weight: 200;
        font-size: 16px;
        padding: 0 0;
    }

    .ui label > .labelPeople{
        background-color: transparent;
        font-weight: 400;
        font-size: 15px;
        border: 1px solid grey;
        border-radius: 5rem !important;
    }
 

.formGroup {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
    font-weight: 200;
    font-size: 15px;
}


.field {
    font-weight: 400;
    font-size: 15px;
}